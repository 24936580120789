import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import EditionDisplay from './components/EditionDisplay';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import LatestEdition from "./routes/LatestEdition";
import {editionsList} from "./resources/editions/EditionsList";
import UnknownPage from "./UnknownPage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route index element={<LatestEdition/>}/>
                {editionsList.map(e =>
                    <Route path={"editions/" + e.edition} key={e.edition}
                           element={<EditionDisplay filePath={e.filePath}/>}
                    />)
                }
                <Route path="*" element={<UnknownPage/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
