import {Worker} from "@react-pdf-viewer/core";
import PDFViewer from "./PDFViewer";

const workerUrl = "https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js";

function EditionDisplay({filePath}) {
    return (<Worker style={{textAlign: "center"}} workerUrl={workerUrl}><PDFViewer fileUrl={filePath}/></Worker>);
}

export default EditionDisplay;
