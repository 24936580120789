// Import the main component
import {Viewer, ViewMode} from '@react-pdf-viewer/core';
import Favicon from 'react-favicon';
import '@react-pdf-viewer/core/lib/styles/index.css';
import favicon from '../favicon.ico';
import {useCallback} from "react";

function PDFViewer({fileUrl}) {

    const setRenderRange = useCallback((visiblePagesRange) => {
        return {
            startPage: visiblePagesRange.startPage - 5,
            endPage: visiblePagesRange.endPage + 5,
        };
    }, []);

    return (
        <div>
            <Favicon url={favicon}/>
            <Viewer fileUrl={fileUrl}
                    viewMode={ViewMode.SinglePage}
                    setRenderRange={setRenderRange}
            />;
        </div>
    );
}
export default PDFViewer;
