import EditionDisplay from "../components/EditionDisplay";
import {editionsList} from "../resources/editions/EditionsList";


function LatestEdition() {
    const latestEdition = editionsList[editionsList.length-1];
    return (<EditionDisplay filePath={latestEdition.filePath}/>);
}

export default LatestEdition;
